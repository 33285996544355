import React from 'react'
import Script from 'next/script'

import styles from './Copyright.module.scss'

export default React.memo(function Copyright() {
  return (
    <>
      <Script src="https://vf.knet.cn/seallogo.dll?sn=e563980110000521100260000&size=4"/>
      <div className={styles.container}>
        <p>
          Copyright © 2017-2025 西安青诗剑网络科技有限公司
        </p>
        <p>
          <a style={{ marginLeft: 20 }} href="http://beian.miit.gov.cn" target="_blank">陕ICP备17019909号</a>
        </p>
      </div>
    </>
  )
})
